import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TitleStyled from "../components/styles/titleStyles"
import PolicyStyled from "../components/styles/PolicyStyles"
import SEO from "../components/seo"

const DoNotSellMyPersonalInfoPage = ({ data, pageContext }) => {
  const { doNotSellMyPersonalInfoSingleton } = data.swapi

  return (
    <Layout pageContext={pageContext}>
      <SEO
        title={doNotSellMyPersonalInfoSingleton.metaTitle}
        description={doNotSellMyPersonalInfoSingleton.metaDescription}
        img={
          doNotSellMyPersonalInfoSingleton.metaImage
            ? doNotSellMyPersonalInfoSingleton.metaImage.path
            : ""
        }
        lang="pl"
      />
      <div className="container">
        <PolicyStyled>
          <TitleStyled textAlign="center" linePosition="center" color="#000000">
            <h2
              dangerouslySetInnerHTML={{
                __html: doNotSellMyPersonalInfoSingleton.title,
              }}
            ></h2>
          </TitleStyled>
          <div
            className="policy__container"
            dangerouslySetInnerHTML={{
              __html: doNotSellMyPersonalInfoSingleton.content,
            }}
          />
        </PolicyStyled>
      </div>
    </Layout>
  )
}

export default DoNotSellMyPersonalInfoPage

export const query = graphql`
  query DoNotSellMyPersonalInfoPagePL {
    swapi {
      doNotSellMyPersonalInfoSingleton(lang: "pl") {
        metaTitle
        metaDescription
        metaImage {
          path
        }
        title
        content
      }
    }
  }
`
